import React from 'react'
import Particles from 'react-particles-js';
import Progress from '../../components/progress'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faGithub, faLinkedin, faTwitter, faWhatsapp, faYoutube} from '@fortawesome/free-brands-svg-icons'
import ThemeContext from '../../context'
import './styles.scss'

class Hero extends React.Component {

    static contextType = ThemeContext

    render() {
        return (
            <section id={`${this.props.id}`} className="about" style={{height: this.context.height}}>
                {this.particles()}
                <Row style={{position:"inherit"}}>
                    <Col md={6} className="content">
                        <div className="content-text">
                            <div className="line-text">
                                <h4>About Me</h4>
                            </div>
                            <h3>I'm a Web3 Full Stack developer working at EDNS</h3>
                            <div className="separator" />
                            <p>Hello, I am Alex Wong from Hong Kong. I have 3 years of full stack experience. What I do best and love is typescript. For Now, I mainly work on the web3 project. In these projects, I am mainly responsible for the interaction and development of the full stack and the smart contract.</p>
                            <div className="social social_icons">
                                <FontAwesomeIcon icon={faGithub} className="social_icon" onClick={() => window.open('https://github.com/Alex-Wong-HK')}/>
                                <FontAwesomeIcon icon={faWhatsapp} className="social_icon" onClick={() => window.open('https://api.whatsapp.com/send?phone=85253196889')} />
                                {/*<FontAwesomeIcon icon={faYoutube} className="social_icon" onClick={() => window.open('https://www.youtube.com')} />*/}
                                <FontAwesomeIcon icon={faLinkedin} className="social_icon" onClick={() => window.open('https://www.linkedin.com/in/alex-wong-10b7b41a5/')} />
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className="skills">
                            <div className="line-text">
                                <h4>My Skills</h4>
                            </div>
                            <div className="skills-container">
                                <Progress name="React" value={80} delay={1100} />
                                <Progress name="Express" value={80} delay={1100} />
                                <Progress name="Solidity" value={70} delay={1100} />
                                <Progress name="Spring" value={65} delay={1100} />
                                <Progress name="Mobile App" value={60} delay={1100} />
                                <Progress name="Machine Learning" value={50} delay={1100} />
                                <Progress name="AWS" value={30} delay={1100} />
                            </div>
                    </Col>
                </Row>
            </section>
        )
    }

    particles() {
        return (
            <Particles
                className="particles"
                params={{
                    "particles": {
                        "number": {
                            "value": 50,
                            "density": {
                                "enable": false,
                                "value_area": 5000
                            }
                        },
                        "line_linked": {
                            "enable": true,
                            "opacity": .5
                        },
                        "size": {
                            "value": 1
                        },
                    },
                    "retina_detect": true
            }}/>
        )
    }

}

export default Hero
