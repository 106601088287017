import React from 'react'
import './styles.scss'
import {Row, Col} from 'react-bootstrap'
import AnimationContainer from '../../components/animation-container'
import BaffleText from '../../components/baffle-text'
import ThemeContext from '../../context'
import {ethers} from "ethers";
import Web3Modal from "web3modal";
import {Icon} from '@iconify/react';
import WalletConnectProvider from '@walletconnect/web3-provider';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import toast, {Toaster} from 'react-hot-toast';
import Select from 'react-select'
import IotexLogo from '../../../static/img/iotex_icon.svg'

const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;


class Contact extends React.Component {
    chainIcon = {
        1: <Icon icon="cryptocurrency:eth" color="blue" className="h-12 w-auto"/>,
        5: <Icon icon="cryptocurrency:eth" color="blue" className="h-12 w-auto"/>,
        66: <Icon icon="cryptocurrency:ok" className="h-12 w-auto"/>,
        65: <Icon icon="cryptocurrency:ok" className="h-12 w-auto"/>,
        137: <Icon icon="cryptocurrency:matic" color="purple " className="h-12 w-auto text-purple-500"/>,
        // 4689:<Icon icon="cryptocurrency:iotx"  className="h-12 w-auto"/>,
        // 4690:<Icon icon="cryptocurrency:iotx"  className="h-12 w-auto"/>,
        56: <Icon icon="cryptocurrency:bnb" color="#f3ba2f" className="h-12 w-auto"/>,
        4689: <img src={IotexLogo} alt="React Logo" className="h-12 w-auto"/>,
        4690: <img src={IotexLogo} alt="React Logo" className="h-12 w-auto"/>,
    }

    options = [
        {value: 1, label: 'ETH'},
        {value: 56, label: 'BNB'},
        {value: 137, label: 'MATIC'},
        {value: 4689, label: 'IOTEX'}
    ]
    NETWORK_PARAMS = {
        1: {
            chainId: '0x1', // 137
        },
        137: {
            chainId: '0x89', // 137
            rpcUrls: "https://polygon-rpc.com",
            chainName: 'Polygon Mainnet',
            nativeCurrency: {
                name: 'MATIC',
                symbol: 'MATIC',
                decimals: 18,
            },
            blockExplorerUrls: ['https://polygonscan.com/'],
        },
        4689: {
            chainId: '0x1251', // 4689
            rpcUrls: [`https://babel-api.mainnet.iotex.io`],
            chainName: 'IoTeX',
            nativeCurrency: {
                name: 'IOTX',
                symbol: 'IOTX',
                decimals: 18,
            },
            blockExplorerUrls: ['https://iotexscan.io/'],
        },
        56: {
            chainId: '0x38', // 56
            rpcUrls: [`https://bsc-dataseed.binance.org/`],
            chainName: 'BNB',
            nativeCurrency: {
                name: 'BNB',
                symbol: 'BNB',
                decimals: 18,
            },
            blockExplorerUrls: [' https://bscscan.com'],
        },

    }

    constructor(props) {
        super(props)
        this.state = {
            name: "",
            email: "",
            phone: "",
            message: "",
            error: false,
            show: false,
            amount: 0,
            chain: 1,
        }
        this.show = this.show.bind(this)
    }

    static contextType = ThemeContext

    handleDepositeAmountChange = (evt) => {
        if (rx_live.test(evt.target.value))
            this.setState({amount: evt.target.value});
    }

    async handleSendETHOnClick() {
        let saveProvide;
        const providerOptions = {
            walletconnect: {
                package: WalletConnectProvider,
                options: {
                    infuraId: "e6f7ae043bf941658aa7e91826ecaff5",
                },
            },
            binancechainwallet: {
                package: true,
            },
            coinbasewallet: {
                package: CoinbaseWalletSDK,
                options: {
                    appName: 'Alex Wong',
                    infuraId: "e6f7ae043bf941658aa7e91826ecaff5",
                },
            },
        };
        const web3Modal = new Web3Modal({
            network: "mainnet",
            providerOptions,// optional
            cacheProvider: true, // optional
            // required
        });
        web3Modal.clearCachedProvider();
        const instance = await web3Modal.connect();
        const provider = new ethers.providers.Web3Provider(instance);
        saveProvide = instance
        try {
            await provider.send('wallet_switchEthereumChain', [{chainId: this.NETWORK_PARAMS[this.state.chain].chainId}]);
        } catch (error) {
            console.log(this.NETWORK_PARAMS[this.CHAIN_ID])
            if (error.code === 4902) {
                await provider.send('wallet_addEthereumChain', [this.NETWORK_PARAMS[this.state.chain]]);
            } else {
                await this.init();
            }
        }


        const provider_afterChangeNetwork = new ethers.providers.Web3Provider(saveProvide);
        const signer = provider_afterChangeNetwork.getSigner();
        const tx = await signer.sendTransaction({
            to: "0x14A1A496fABc43bFAfC358005dE336a7B5222b20",
            value: ethers.utils.parseEther(`${this.state.amount}`)
        });
    }

    show() {
        this.setState({show: true})
    }

    check(val) {
        if (this.state.error && val === "") {
            return false
        } else {
            return true
        }
    }

    submit() {
        if (this.state.name === "" || this.state.email === "" || this.state.message === "") {
            this.setState({error: true})
            toast.error('Please Fill the info.');
        } else {
            this.setState({error: false})
            window.open(`https://wa.me/85253196889?text=${this.state.message} %0D%0A From:${this.state.email}(${this.state.name})`)
        }
    }

    render() {
        return (
            <section id={`${this.props.id}`} className="contact" style={{height: this.context.height}}>
                <Toaster/>
                <Row>
                    <Col md={2} className="side">
                        <h2>
                            <BaffleText text="Contact" revealDuration={500} revealDelay={500} parentMethod={this.show}
                                        callMethodTime={1100}/>
                        </h2>
                    </Col>
                    <Col md={5} className="form">
                        {this.form()}
                    </Col>
                    <Col md={5} className="map">
                        {this.map()}
                    </Col>
                </Row>
            </section>
        )
    }

    form() {
        if (this.state.show || this.context.height === "auto") {
            return (
                <AnimationContainer delay={0} animation="fadeInUp fast">
                    <div className="form-container">
                        <div className="line-text">
                            <h4>Get In Touch</h4>
                            <AnimationContainer delay={50} animation="fadeInUp fast">
                                <div className="form-group">
                                    <input type="text" className={`name ${this.check(this.state.name) ? "" : "error"}`}
                                           placeholder="Name" onChange={e => this.setState({name: e.target.value})}/>
                                </div>
                            </AnimationContainer>
                            <AnimationContainer delay={100} animation="fadeInUp fast">
                                <div className="form-group">
                                    <input type="text"
                                           className={`email ${this.check(this.state.email) ? "" : "error"}`}
                                           placeholder="Email" onChange={e => this.setState({email: e.target.value})}/>
                                </div>
                            </AnimationContainer>
                            <AnimationContainer delay={150} animation="fadeInUp fast">
                                <div className="form-group">
                                    <input type="text" className="phone" placeholder="Phone"
                                           onChange={e => this.setState({phone: e.target.value})}/>
                                </div>
                            </AnimationContainer>
                            <AnimationContainer delay={200} animation="fadeInUp fast">
                                <div className="form-group">
                                    <textarea className={`message ${this.check(this.state.message) ? "" : "error"}`}
                                              placeholder="Message"
                                              onChange={e => this.setState({message: e.target.value})}></textarea>
                                </div>
                            </AnimationContainer>
                            <AnimationContainer delay={250} animation="fadeInUp fast">
                                <div className="submit">
                                    <button className={`hover-button ${this.state.error ? "error" : ""}`}
                                            onClick={() => this.submit()}>
                                        <span>Send Message</span>
                                    </button>
                                </div>
                            </AnimationContainer>
                        </div>
                    </div>
                </AnimationContainer>
            )
        }
    }

    map() {
        if (this.state.show || this.context.height === "auto") {
            return (
                <AnimationContainer delay={1000} animation="fadeIn fast" height={this.context.height}>
                    <div style={{
                        minHeight:"500px",
                        height: "100%",
                        width: "100%",
                        backgroundColor: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: 'space-between',
                        flexDirection: 'column'
                    }}
                    className="form-container"
                    >
                        <div style={{
                            display:"flex",
                            height: "33%",
                            alignItems: "center",
                            justifyContent: 'center',
                            marginTop:"5rem"

                        }}>{this.chainIcon[this.state.chain]}</div>


                        <div style={{height: "33%" , justifyContent: 'center', display:"flex", flexDirection:"column"}}>
                            <p>Buy me a coffee :)</p>
                            <div>
                                <p className="flex">
                                    <input
                                    type="text"
                                    id="depositedAmount"
                                    maxLength={9}
                                    pattern="[+-]?\d+(?:[.,]\d+)?"
                                    placeholder="Enter amount"
                                    onChange={this.handleDepositeAmountChange}
                                    value={this.state.amount}
                                />

                                    <Select options={this.options}
                                            defaultValue={{value: 1, label: 'ETH'}}
                                            onChange={(e) => {
                                                this.setState({chain: e.value})
                                            }
                                            }
                                            style={{width: "auto"}}
                                    ></Select>
                                </p>

                                {/*{this.state.chain}*/}

                            </div>
                            <button onClick={() => {
                                toast.promise(this.handleSendETHOnClick(), {
                                    loading: 'Loading',
                                    success: 'Thanks for support!',
                                    error: 'Wallet not enough balance to send.',
                                })
                            }}
                                    style={{
                                        fontFamily: "sans-serif",
                                        fontSize: "18px",
                                        padding: "12px 32px",
                                        margin: "1rem",
                                        cursor: "pointer",
                                        transition: "all 0.3s ease",
                                        borderRadius: "50px"
                                    }}
                            >Send
                            </button>
                        </div>

                        <div style={{height: "33%"}}>

                        </div>

                    </div>
                </AnimationContainer>
            )
        }
    }

}

export default Contact
